import type { AxiosError } from 'axios'
import axios from 'axios'
import { Constants } from '@/utils/enums'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { redirectPath } from '@/router'

// Define Axios Response / Error Types
export type _AxiosError = AxiosError & {
  response: {
    data: { message: string }
  }
}

// docs: https://github.com/axios/axios#request-config
axios.defaults.baseURL = Constants['BASE_URL']
axios.defaults.withCredentials = true
axios.defaults.timeout = 300_000 // 5分

const refreshUrl = '/auth/refresh'
const loginUrl = '/auth/login'
axios.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if (err.config && err.response?.status == 401) {
      if (err.config.url == refreshUrl) {
        // API call時に401が判明してrefreshしたケース
        try {
          const data = JSON.parse(err.config.data)
          if (!data.redirect) {
            _ElMessage({ type: 'error', message: '再ログインしてください' })
            setTimeout(() => {
              return (location.href = `${redirectPath}/?redirect=${data.redirect}`)
            }, 100)
          } else {
            _ElMessage({ type: 'error', message: '再ログインしてください' })
            setTimeout(() => {
              return (location.href = redirectPath)
            }, 100)
          }
        } catch {
          _ElMessage({ type: 'error', message: '再ログインしてください' })
          setTimeout(() => {
            return (location.href = redirectPath)
          }, 100)
        }
      } else if (err.config.url !== loginUrl) {
        return axios.post(refreshUrl, err.config.params).then(() => {
          return axios.request(err.config)
        })
      }
    } else if (err.code === 'ECONNABORTED') {
      _ElMessage({ type: 'error', message: 'タイムアウトしました' })
    } else if (!err?.response) {
      _ElMessage({ type: 'error', message: 'ネットワークエラーが発生しました' })
    }
    // NavigationGuardでauthedHelloしてるケース
    return Promise.reject(err)
  }
)

export default axios
