import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import TokenValidatorView from '@/views/TokenValidatorView.vue'
import UrlSchemeView, { type UrlSchemeName } from '@/views/UrlSchemeView.vue'
import ProjectsHomeView from '../views/ProjectsHomeView.vue'
import CatalogsHomeView from '@/views/CatalogsHomeView.vue'
import CasesHomeView from '@/views/CasesHomeView.vue'
import SettingsView from '@/views/SettingsView.vue'
import { useAuthStore } from '@/stores/authStore'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { type PageTypes, type SettingsMenuItems, AdminOnlySettingsMenuItems } from '@/utils/enums'
import { useUserStore } from '@/stores/userStore'
import type { TokenValidationActionType } from '@/types/auth.type'
import type { MyAccountEditTarget } from '@/components/settings/MyAccount.vue'
import { useProjectStore } from '@/stores/projectStore'
import { extractIdFromHash } from '@/utils/hash.helper'
import { useCaseItemStore } from '@/stores/caseItemStore'

const loginPath = '/login'
export const redirectPath = loginPath

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'top',
      redirect: () => {
        // htmlを表示するためのworkaround
        return (window.location.href = '/top.html')
      },
      meta: { requiresAuth: false }
    },
    {
      path: '/contact',
      name: 'contact',
      redirect: () => {
        // htmlを表示するためのworkaround
        return (window.location.href = '/contact.html')
      },
      meta: { requiresAuth: false }
    },
    {
      path: '/terms',
      name: 'terms',
      redirect: () => {
        // htmlを表示するためのworkaround
        return (window.location.href = '/terms.html')
      }
    },
    {
      path: loginPath,
      name: 'login',
      component: LoginView,
      props: (route: { query: { redirect?: string } }) => ({
        redirect: route.query.redirect
      }),
      meta: { requiresAuth: false }
    },
    {
      path: '/link',
      name: 'urlScheme',
      component: UrlSchemeView,
      props: (route: { query: { name: UrlSchemeName; ids: string } }) => ({
        name: route.query.name,
        ids: route.query.ids
      }),
      meta: { requiresAuth: false }
    },
    {
      path: '/validates',
      name: 'tokenValidator',
      component: TokenValidatorView,
      props: (route: { query: { token: string; actionType: TokenValidationActionType } }) => ({
        token: route.query.token,
        actionType: route.query.actionType
      }),
      meta: { requiresAuth: false }
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectsHomeView,
      props: (route: {
        query: { id?: string; itemId?: string; cId?: string; pageType?: PageTypes }
      }) => ({
        projectIdHash: route.query.id,
        projectItemIdHash: route.query.itemId,
        caseItemIdHash: route.query.cId,
        pageType: route.query.pageType
      }),
      meta: { requiresAuth: true }
    },
    {
      path: '/catalogs',
      name: 'catalogs',
      component: CatalogsHomeView,
      props: (route: {
        query: { menu?: SettingsMenuItems; resourceId?: string; pageType?: PageTypes }
      }) => ({
        menu: route.query.menu,
        resourceId: route.query.resourceId,
        pageType: route.query.pageType
      }),
      meta: { requiresAuth: true }
    },
    {
      path: '/cases',
      name: 'cases',
      component: CasesHomeView,
      props: (route: { query: { id?: string; csReqId?: string } }) => ({
        caseIdHash: route.query.id,
        csRequestIdHash: route.query.csReqId
      }),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      props: (route: {
        query: { menu?: SettingsMenuItems; edit?: MyAccountEditTarget; threadId?: string }
      }) => ({
        menu: route.query.menu,
        myAccountEdit: route.query.edit,
        threadIdHash: route.query.threadId
      }),
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (!to.meta.requiresAuth) {
    next()
  } else {
    if (authStore.is_loggedin) {
      mainGuard()
    } else {
      // valid cookie持ってるかチェック
      authStore
        .authedHello({ redirect: to.fullPath })
        .then(() => {
          mainGuard()
        })
        .catch(() => {
          redirectToHome('再ログインしてください')
        })
    }
  }

  function mainGuard() {
    const userStore = useUserStore()
    // pw
    if (userStore.get_current_user.requiredUpdatePassword) {
      if (to.name == 'settings' && to.query.menu == 'myAccount' && to.query.edit == 'password') {
        // avoid infinite loop
        next()
      } else {
        _ElMessage({
          type: 'error',
          message: 'パスワードの更新が必要です'
        })
        next({ path: '/settings', query: { menu: 'myAccount', edit: 'password' } })
      }
    }
    switch (userStore.get_current_user.role) {
      case 'admin':
        next()
        break
      case 'supervisor':
        switch (to.name) {
          case 'projects':
            if (!to.query.id) {
              next()
              break
            }
            checkProjectDetail(to.query.id as string).then((b) => {
              if (b) {
                next()
              } else {
                redirectToHome('権限がありません')
              }
            })
            break
          case 'catalogs':
            next()
            break
          case 'cases':
            next()
            break
          case 'settings':
            if (
              to.query.menu &&
              Object.keys(AdminOnlySettingsMenuItems).includes(to.query.menu as SettingsMenuItems)
            ) {
              redirectToHome('権限がありません')
            } else {
              next()
            }
            break
        }
        break
      case 'normal':
        switch (to.name) {
          case 'projects':
            if (!to.query.id) {
              next()
              break
            }
            checkProjectDetail(to.query.id as string).then((b) => {
              if (b) {
                next()
              } else {
                redirectToHome('権限がありません')
              }
            })
            break
          case 'catalogs':
            redirectToHome('権限がありません')
            break
          case 'cases':
            redirectToHome('権限がありません')
            break
          case 'caseDetailView':
            checkCaseItemDetail(to.params.caseItemIdHash as string).then((b) => {
              if (b) {
                next()
              } else {
                redirectToHome('権限がありません')
              }
            })
            break
          case 'settings':
            if (
              to.query.menu &&
              Object.keys(AdminOnlySettingsMenuItems).includes(to.query.menu as SettingsMenuItems)
            ) {
              redirectToHome('権限がありません')
            } else {
              next()
            }
            break
        }
        break
      default:
        redirectToHome('不正なロール設定です')
    }
  }

  async function checkCaseItemDetail(idHash: string): Promise<boolean> {
    // caseItemIdをdecodeしてapi call => 403が出たらredirectする
    const caseItemStore = useCaseItemStore()
    try {
      const cId = extractIdFromHash(idHash)
      await caseItemStore.get_case_item_detail(cId, true)
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  async function checkProjectDetail(idHash: string): Promise<boolean> {
    // projectIdをdecodeしてapi call => 403が出たらredirectする
    const projectStore = useProjectStore()
    try {
      const pId = extractIdFromHash(idHash)
      await projectStore.get_project_detail(pId, true)
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  function redirectToHome(msg: string) {
    _ElMessage({ type: 'error', message: msg })
    next({
      path: redirectPath,
      query: { redirect: to.fullPath }
    })
  }
})

export default router
