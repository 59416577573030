<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useProjectStore } from '@/stores/projectStore'
import { computed, watch, onMounted, ref } from 'vue'
import { useCategoryStore } from '@/stores/categoryStore'
import { GroupLabel, GroupLabelLabel } from '@/types/topCategory.type'
import { ArrowRightBold, ArrowLeftBold, Download } from '@element-plus/icons-vue'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import type { ProjectDetail, UpdateProjectPayload } from '@/types/project.type'
import { Check, Edit } from '@element-plus/icons-vue'
import { downloadFile } from '@/utils/file.helper'
const CATALOG_DATA_ERROR = '設定不備があるようです。管理者にご連絡ください。'
const props = defineProps<{
  projectId: number
}>()
const projectStore = useProjectStore()
const projectData = computed(() => projectStore.current_project)
const categoryStore = useCategoryStore()
categoryStore.clear_current_top_category()
categoryStore.get_top_categories()

const currentStepNum = ref(0)
const currentProjectData = ref<ProjectDetail>() // prev/nextで何を表示すべきかはstateから計算できないから
async function init() {
  try {
    currentProjectData.value = await projectStore.get_project_detail(props.projectId, true)
  } catch (e) {
    _ElMessage({
      type: 'error',
      message: '案件情報の取得に失敗しました。時間をおいてから画面を更新してください。'
    })
    return
  }
  // clear select
  selectedTopCategoryId.value = -1
  selectedObjectiveId.value = -1
  selectedTobeItemId.value = -1
  // currentStepNum
  if (currentProjectData.value.tobeItemId) {
    currentStepNum.value = 3
  } else if (currentProjectData.value.objectiveId) {
    currentStepNum.value = 2
  } else if (currentProjectData.value.topCategoryId) {
    currentStepNum.value = 1
    setDefaultObjectiveId() // 部署は主目的を自動選択する
  } else {
    currentStepNum.value = 0
  }

  // topCategoryDetail
  if (projectStore.current_top_category) {
    categoryStore.get_top_category_detail(projectStore.current_top_category.id).catch(() => {})
  }
}
onMounted(() => {
  init()
})
watch(
  () => props.projectId,
  () => {
    init()
  }
)

const isLoadingImage = ref(false)

// step0: topCategory
const topCategoryCandidates = computed(() => {
  return [
    {
      groupLabel: GroupLabel.biz,
      candidates: categoryStore.top_categories.filter((e) => e.groupLabel == 'biz')
    },
    {
      groupLabel: GroupLabel.department,
      candidates: categoryStore.top_categories.filter((e) => e.groupLabel == 'department')
    }
  ]
})
const selectedTopCategoryId = ref(-1)
const currentTopCategoryId = computed(() => {
  if (!projectData.value) {
    return -1
  } else if (selectedTopCategoryId.value !== -1) {
    return selectedTopCategoryId.value
  } else {
    return projectData.value.topCategoryId ? projectData.value.topCategoryId : -1
  }
})
const asisPng = computed(() => {
  return categoryStore.current_asis_image?.asisPng || undefined
})
const filledTopCategoryName = computed(() => {
  return projectStore.current_top_category?.name || ''
})
function getAsisImageUrl(topCategoryId: number, fileId: number) {
  return categoryStore.get_asis_image_url(topCategoryId, fileId, 'png')
}
function selectTopCategoryId(id: number) {
  isLoadingImage.value = true
  selectedTopCategoryId.value = id
  categoryStore.clear_current_top_category()
  categoryStore.get_top_category_detail(id).catch(() => {})
  setTimeout(() => {
    isLoadingImage.value = false
  }, 1000)
}
const canFillTopCategoryId = computed(() => {
  if (selectedTopCategoryId.value == -1 && !projectStore.current_top_category) {
    return false
  }
  return true
})
function promptCsRequest() {
  _ElMessage({
    type: 'info',
    message: '未対応の業種・部門に関する案件については、右上のヘルプマークからお問い合わせください'
  })
}
function fillTopCategoryId() {
  if (!canFillTopCategoryId.value) {
    _ElMessage({ type: 'error', message: '業種・部門を選択してください' })
    return
  }
  if (selectedTopCategoryId.value == -1) {
    // 登録済みなのでスルーして次へ
    stepForward()
    return
  }
  // fill
  projectStore
    .fill_project({
      id: props.projectId,
      topCategoryId: selectedTopCategoryId.value
    })
    .then(() => {
      stepForward()
    })
    .catch((err) => {
      _ElMessage({ type: 'error', message: err })
    })
}
// step1: objective
const selectedObjectiveId = ref(-1)
const shouldSkip = computed(() => projectStore.should_skip_objective)
const objectiveCandidates = computed(() => categoryStore.current_objective_candidates)
const currentObjectiveId = computed(() => {
  if (selectedObjectiveId.value !== -1) {
    return selectedObjectiveId.value
  }
  return projectStore.current_objective?.id || -1
})
const tobePng = computed(() => {
  const targetTobeImageId =
    objectiveCandidates.value.find((e) => e.id == currentObjectiveId.value)?.targetTobeImageId || -1
  return categoryStore.current_tobe_images_candidates.find((e) => e.id == targetTobeImageId)
    ?.tobePng
})
const filledObjectiveLabel = computed(() => {
  if (projectStore.should_skip_objective) {
    return '確認済み'
  }
  return projectStore.current_objective?.title || ''
})
function getTobeImageUrl(objectiveId: number, fileId: number) {
  const tobeImageId =
    objectiveCandidates.value.find((e) => e.id == objectiveId)?.targetTobeImageId || -1
  if (tobeImageId == -1) {
    return undefined
  }
  return categoryStore.get_tobe_image_url(tobeImageId, fileId, 'png')
}
function setDefaultObjectiveId() {
  if (!shouldSkip.value) {
    return
  }
  const targetId = (() => {
    try {
      return categoryStore.current_objective_candidates[0].id as number
    } catch (e) {
      return -1
    }
  })()
  if (targetId !== -1) {
    selectedObjectiveId.value = targetId
  }
}
function selectObjectiveId(id: number) {
  isLoadingImage.value = true
  selectedObjectiveId.value = id
  setTimeout(() => {
    isLoadingImage.value = false
  }, 1000)
}
const canFillObjectiveId = computed(() => {
  if (selectedObjectiveId.value == -1 && !projectStore.current_objective) {
    return false
  }
  return true
})
function fillObjectiveId() {
  if (!canFillObjectiveId.value) {
    _ElMessage({ type: 'error', message: '主目的を選択してください' })
    return
  }
  if (selectedObjectiveId.value == -1) {
    // 登録済みなのでスルーして次へ
    stepForward()
    return
  }
  // fill
  projectStore
    .fill_project({
      id: props.projectId,
      objectiveId: selectedObjectiveId.value
    })
    .then(() => {
      stepForward()
    })
    .catch((err) => {
      _ElMessage({ type: 'error', message: err })
    })
}

// step2: tobeItem
const selectedTobeItemId = ref(-1)
const tobeItemCandidates = computed(() => {
  const tobeImageId = projectStore.current_objective?.targetTobeImageId || -1
  return (
    categoryStore.current_tobe_images_candidates.find((e) => e.id == tobeImageId)?.tobeItems || []
  )
})
const currentTobeItemId = computed(() => {
  if (selectedTobeItemId.value !== -1) {
    return selectedTobeItemId.value
  }
  return projectStore.current_tobe_item?.id || -1
})
const canFillTobeItemId = computed(() => {
  if (selectedTobeItemId.value == -1 && !projectStore.current_tobe_item) {
    return false
  }
  return true
})
function fillTobeItemId() {
  if (!canFillTobeItemId.value) {
    _ElMessage({ type: 'error', message: '取り組む施策を選択してください' })
    return
  }
  if (selectedTobeItemId.value == -1) {
    // 登録済みなのでスルーして次へ
    stepForward()
    return
  }
  // fill
  projectStore
    .fill_project({
      id: props.projectId,
      tobeItemId: selectedTobeItemId.value
    })
    .then(() => {
      stepForward()
    })
    .catch((err) => {
      _ElMessage({ type: 'error', message: err })
    })
}

// confirm
const isLoadingConfirm = ref(false)
const solutions = computed(() => {
  return tobeItemCandidates.value.find((e) => e.id == currentTobeItemId.value)?.solutions || []
})
function confirm() {
  _ElConfirm(`案件情報を確定して次に進みます。よろしいですか？`, `ソリューションの検討に進む`, {
    confirmButtonText: '進む',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      isLoadingConfirm.value = true
      projectStore
        .confirm_project(props.projectId)
        .then(() => {
          _ElMessage({
            type: 'success',
            message: '案件情報を確定しました'
          })
        })
        .catch(() => {})
        .finally(() => {
          isLoadingConfirm.value = false
        })
    })
    .catch(() => {})
}

// back & forth
function stepBack() {
  currentStepNum.value -= 1
}
function stepForward() {
  // go next
  currentStepNum.value += 1
  // clear selected
  selectedTopCategoryId.value = -1
  selectedObjectiveId.value = -1
  selectedTobeItemId.value = -1

  // 部署は主目的自動選択
  if (currentStepNum.value == 1) {
    setDefaultObjectiveId()
  }
}

// update
const isUpdating = ref(false)
const isUpdateMode = ref(false)
const updateProjectDataPayload = ref<Pick<UpdateProjectPayload, 'id' | 'title'>>({
  id: -1,
  title: ''
})
function openUpdateDialog() {
  if (!projectData.value) {
    _ElMessage({ type: 'error', message: '案件情報が取得できませんでした' })
    return
  }
  isUpdateMode.value = true
  updateProjectDataPayload.value = {
    id: projectData.value.id,
    title: projectData.value.title
  }
}
function updateProjectData() {
  if (!projectData.value) {
    return
  }
  isUpdating.value = true
  projectStore
    .update_project(updateProjectDataPayload.value)
    .then(() => {
      _ElMessage({ type: 'success', message: 'タイトルを更新しました' })
      isUpdateMode.value = false
    })
    .catch((e) => {
      console.error(e)
      _ElMessage({ type: 'error', message: 'タイトルの更新に失敗しました' })
    })
    .finally(() => {
      isUpdating.value = false
    })
}

// imageの詳細表示
const isTobeImageModalOpen = ref(false)
const isAsisImageModalOpen = ref(false)

async function downloadAsisPng() {
  if (!asisPng.value) {
    _ElMessage({ type: 'error', message: '画像が選択されていません' })
    return
  }
  // currentTopCategoryIdが-1 つまり業種・部門が確定されていない場合はselectedTopCategoryIdの値を付与
  const topCategoryId =
    currentTopCategoryId.value === -1 ? selectedTopCategoryId.value : currentTopCategoryId.value
  if (topCategoryId === -1) {
    _ElMessage({ type: 'error', message: '業種・部門を選択してください' })
    return
  }
  try {
    const { data, filename } = await categoryStore.download_asis_image(
      topCategoryId,
      asisPng.value.id,
      'png'
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  }
}

async function downloadTobePng() {
  if (!tobePng.value) {
    _ElMessage({ type: 'error', message: '画像が選択されていません' })
    return
  }
  try {
    const { data, filename } = await categoryStore.download_tobe_image(
      tobePng.value.tobeImageId,
      tobePng.value.id,
      'png'
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  }
}
</script>

<template>
  <template v-if="projectData">
    <el-dialog :close-on-press-escape="false" v-model="isUpdateMode">
      <template #header> 案件の編集 </template>
      <el-form :v-model="updateProjectDataPayload" label-position="left" label-width="80px">
        <el-form-item label="タイトル">
          <el-input v-model="updateProjectDataPayload.title" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-dialog-footer_both_side">
          <el-button @click="isUpdateMode = false">キャンセル</el-button>
          <el-button type="primary" @click="updateProjectData" :loading="isUpdating">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </template>
  <template v-if="tobePng">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isTobeImageModalOpen"
      top="5vh"
      width="80%"
      class="ix-dialog"
    >
      <template #header>
        <div class="image-modal-title-container">
          <div class="image-modal-title">プレビュー</div>
          <el-popover placement="top-start" :width="200" trigger="hover" content="ダウンロード">
            <template #reference>
              <el-button :icon="Download" style="font-size: 18px" link @click="downloadTobePng" />
            </template>
          </el-popover>
        </div>
      </template>
      <div class="image-dialog-img-wrapper">
        <div class="image">
          <img
            :src="getTobeImageUrl(currentObjectiveId, tobePng.id)"
            crossOrigin="use-credentials"
          />
        </div>
      </div>
    </el-dialog>
  </template>
  <template v-if="asisPng">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isAsisImageModalOpen"
      top="5vh"
      width="80%"
      class="ix-dialog"
    >
      <template #header>
        <div class="image-modal-title-container">
          <div class="image-modal-title">プレビュー</div>
          <el-popover placement="top-start" :width="200" trigger="hover" content="ダウンロード">
            <template #reference>
              <el-button :icon="Download" style="font-size: 18px" link @click="downloadAsisPng" />
            </template>
          </el-popover>
        </div>
      </template>
      <div class="image-dialog-img-wrapper">
        <div class="image">
          <img
            :src="getAsisImageUrl(currentTopCategoryId, asisPng.id)"
            crossOrigin="use-credentials"
          />
        </div>
      </div>
    </el-dialog>
  </template>
  <div class="project-flow">
    <div v-if="!projectData" class="loading">Loading...</div>
    <template v-else>
      <div class="title-wrapper" @click="openUpdateDialog">
        <div class="title-text">{{ projectData.title }}</div>
        <Edit style="width: 16px; height: 16px; margin-top: 2px" />
      </div>
      <el-steps :active="currentStepNum" finish-status="success" align-center>
        <el-step title="業種・部門の選択">
          <template #description>{{ filledTopCategoryName }}</template>
        </el-step>
        <el-step title="主目的の確認">
          <template #description>{{ filledObjectiveLabel }}</template>
        </el-step>
        <el-step title="取り組む施策の選択"></el-step>
      </el-steps>
      <div class="step-content-wrapper">
        <div v-if="currentStepNum == 0" class="step0-content-wrapper">
          <div class="instruction">業種または部門を1つ選んでください</div>
          <div class="select-top-category">
            <div
              v-for="group in topCategoryCandidates"
              :key="group.groupLabel"
              class="top-category-group"
            >
              <div class="group-label">{{ GroupLabelLabel[group.groupLabel] }}</div>
              <div class="candidates-wrapper">
                <div
                  v-for="candidate in group.candidates"
                  :key="candidate.id"
                  class="top-category-candidate"
                  :class="{ current: candidate.id == currentTopCategoryId }"
                  @click="selectTopCategoryId(candidate.id)"
                >
                  {{ candidate.name }}
                </div>
                <div class="top-category-candidate" @click="promptCsRequest()">その他</div>
              </div>
            </div>
          </div>
          <div
            v-if="currentTopCategoryId !== -1"
            class="asis-image-wrapper"
            v-loading="isLoadingImage"
          >
            <div v-if="asisPng" class="asis-image clickable" @click="isAsisImageModalOpen = true">
              <img
                :src="getAsisImageUrl(currentTopCategoryId, asisPng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="asis-image placeholder">
              {{ CATALOG_DATA_ERROR }}
            </div>
          </div>
        </div>
        <div v-if="currentStepNum == 1" class="step1-content-wrapper">
          <div class="instruction">主目的とありたい姿を確認してください</div>
          <div class="select-objective">
            <div class="objective-candidates-wrapper">
              <div v-if="shouldSkip" class="objective-candidate" @click="setDefaultObjectiveId()">
                （選択不要）
              </div>
              <template v-else>
                <div
                  v-for="objective in objectiveCandidates"
                  :key="objective.id"
                  class="objective-candidate"
                  :class="{ current: objective.id == currentObjectiveId }"
                  @click="selectObjectiveId(objective.id)"
                >
                  {{ objective.title }}
                </div>
                <div v-if="!objectiveCandidates.length" class="objective-candidate-placeholder">
                  {{ CATALOG_DATA_ERROR }}
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="currentObjectiveId !== -1"
            class="tobe-image-wrapper"
            v-loading="isLoadingImage"
          >
            <div v-if="tobePng" class="tobe-image clickable" @click="isTobeImageModalOpen = true">
              <img
                :src="getTobeImageUrl(currentObjectiveId, tobePng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="tobe-image placeholder">{{ CATALOG_DATA_ERROR }}</div>
          </div>
        </div>
        <div v-if="currentStepNum == 2" class="step2-content-wrapper">
          <div class="instruction">ありたい姿のために取り組む施策を1つ選んでください</div>
          <div v-if="currentObjectiveId !== -1" class="tobe-image-wrapper">
            <div v-if="tobePng" class="tobe-image clickable" @click="isTobeImageModalOpen = true">
              <img
                :src="getTobeImageUrl(currentObjectiveId, tobePng.id)"
                crossOrigin="use-credentials"
              />
            </div>
            <div v-else class="tobe-image placeholder">{{ CATALOG_DATA_ERROR }}</div>
          </div>
          <div class="tobe-item-candidates-wrapper">
            <div
              v-for="tobeItem in tobeItemCandidates"
              :key="tobeItem.id"
              class="tobe-item-candidate"
              :class="{ current: tobeItem.id == currentTobeItemId }"
              @click="selectedTobeItemId = tobeItem.id"
            >
              {{ tobeItem.identifierLabel + '. ' + tobeItem.description }}
            </div>
          </div>
        </div>
        <div
          v-if="currentStepNum == 3"
          class="confirm-content-wrapper"
          v-loading="isLoadingConfirm"
        >
          <div class="output-caption">
            ご入力いただいた案件情報から、適切なソリューションを絞り込みました。
          </div>
          <div class="solutions-wrapper">
            <div v-for="s in solutions" :key="s.id" class="solution-item">
              <Check style="width: 16px; height: 16px" />
              {{ s.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer-action">
        <div v-if="currentStepNum !== 0" class="arrow-btn" @click="stepBack()">
          <ArrowLeftBold style="width: 16px; height: 16px" />
          戻る
        </div>
        <div
          v-if="currentStepNum == 0"
          class="arrow-btn"
          :class="{ disabled: !canFillTopCategoryId }"
          @click="fillTopCategoryId()"
        >
          <ArrowRightBold style="width: 16px; height: 16px" />
          次へ
        </div>
        <div
          v-if="currentStepNum == 1"
          class="arrow-btn"
          :class="{ disabled: !canFillObjectiveId }"
          @click="fillObjectiveId()"
        >
          <ArrowRightBold style="width: 16px; height: 16px" />
          次へ
        </div>
        <div
          v-if="currentStepNum == 2"
          class="arrow-btn"
          :class="{ disabled: !canFillTobeItemId }"
          @click="fillTobeItemId()"
        >
          <ArrowRightBold style="width: 16px; height: 16px" />
          次へ
        </div>
        <div
          v-if="currentStepNum == 3"
          class="arrow-btn"
          :class="{ disabled: !solutions.length }"
          @click="confirm()"
        >
          <ArrowRightBold style="width: 16px; height: 16px" />
          進む
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.project-flow {
  padding: 0 0 240px 0;
  height: 100%;
  overflow-y: scroll;
}
.loading {
  padding: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.disabled');
}
.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 14px;
  max-width: 100%;
  .title-text {
    font-size: 14px;
    font-weight: bold;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: v-bind('colors.text.base');
  }
}
.clickable {
  cursor: pointer;
}

/* step0 */
.step-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.step0-content-wrapper {
  width: 80%;
  padding: 4px 40px 0;
  .instruction {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
}
.select-top-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
  height: auto;
}
.top-category-group {
  width: 50%;
  padding-left: 24px;
  padding-right: 24px;
}
.group-label {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 4px;
  border-bottom: 4px solid v-bind('colors.service.darkBlue');
}
.candidates-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  padding-top: 8px;
  padding-left: 4px;
  height: auto;
}
.top-category-candidate {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  padding: 4px 24px;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
  &.current,
  &:hover {
    background-color: v-bind('colors.service.darkBlue');
    color: v-bind('colors.text.white');
  }
}
.asis-image-wrapper,
.tobe-image-wrapper {
  width: 100%;
  margin-top: 16px;
}
.asis-image,
.tobe-image {
  width: 100%;
  min-height: 50vh;
  border: 1px solid v-bind('colors.border.base');
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
    color: v-bind('colors.text.disabled');
    font-weight: bold;
    background-color: v-bind('colors.bg.gray05');
  }
}

/* step1 */
.step1-content-wrapper {
  width: 80%;
  padding: 4px 40px 0;
  .instruction {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
}
.select-objective {
  display: flex;
  justify-content: center;
  align-items: center;
}
.objective-candidates-wrapper {
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  gap: 14px;
  height: auto;
}
.objective-candidate {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  padding: 4px 24px;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
  &.current,
  &:hover {
    background-color: v-bind('colors.service.darkBlue');
    color: v-bind('colors.text.white');
  }
}
.objective-candidate-placeholder {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  padding: 4px 24px;
  border: 1px solid v-bind('colors.utility.redBg');
  background-color: v-bind('colors.utility.redBg');
  color: v-bind('colors.utility.red');
}

/* step2 */
.step2-content-wrapper {
  width: 80%;
  padding: 4px 40px 0;
  .instruction {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
}
.tobe-item-candidates-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: auto;
  margin-top: 16px;
}
.tobe-item-candidate {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  min-height: 32px;
  height: auto;
  padding: 6px 24px;
  cursor: pointer;
  border: 1px solid v-bind('colors.border.base');
  &.current,
  &:hover {
    background-color: v-bind('colors.service.darkBlue');
    color: v-bind('colors.text.white');
  }
}

/* confirm */
.confirm-content-wrapper {
  width: auto;
  padding: 0 40px;
}
.output-caption {
  margin-top: 40px;
  font-size: 18px;
  font-weight: bold;
}
.solutions-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.solution-item {
  height: 32px;
  line-height: 32px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

/* footer action */
.footer-action {
  position: fixed;
  bottom: 0;
  width: calc(100% - 280px - 40px); /* 280: sidebar, 40: el-main padding x 2 */
  height: 64px;
  background-color: v-bind('colors.bg.gray01');
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-btn {
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: v-bind('colors.service.darkBlue');
  border: 3px solid v-bind('colors.service.darkBlue');
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  &:hover {
    background-color: v-bind('colors.service.darkBlue');
    color: v-bind('colors.text.white');
  }
  &.disabled {
    cursor: not-allowed;
    background-color: v-bind('colors.text.disabled');
    color: v-bind('colors.text.white');
    border-color: transparent;
  }
}
.arrow-btn + .arrow-btn {
  margin-left: 20px;
}
/* Image dialog */
.image-modal-title-container {
  display: flex;
  align-items: center;
}
.image-modal-title {
  margin-right: 10px;
}
.image {
  height: auto;
  border: 1px solid v-bind('colors.border.base');
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
